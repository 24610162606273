
import { defineComponent, ref } from "vue";
import { CardGalerieModel } from "@/models/CardGalerieModel";
import CardGalerie from "@/components/includes/CardGalerie.vue";

export default defineComponent({
  name: "Galerie",
  components: {
    CardGalerie,
  },
  setup() {
    const cardsGaleries = ref<Array<CardGalerieModel>>([
      {
        id: 1,
        image: "/galerie/Galerie_1.webp",
        titre:
          "Le drone Hangi présent sur le stand du ministère de l'intérieur au Milipol 2020",
        categorie: "Hangi",
      },
      {
        id: 2,
        image: "/galerie/Galerie_2.webp",
        titre:
          "Vision de la caméra grand angle 5MPx, de type fish-eye orientable",
        categorie: "Hangi",
      },
      {
        id: 3,
        image: "/galerie/Galerie_3.webp",
        titre: "Mallette de contrôle du drone HANGI",
        categorie: "Hangi",
      },
      {
        id: 4,
        image: "/galerie/Galerie_4.webp",
        titre: "Télécommande du drone Hangi",
        categorie: "Hangi",
      },
      {
        id: 5,
        image: "/galerie/Galerie_5.webp",
        titre: "Malle de rangement du drone et ses accessoires",
        categorie: "Hangi",
      },
    ]);
    return {
      cardsGaleries,
    };
  },
});
