<template>
  <section class="header">
    <div class="hero-img-header big-hero-header">
      <div class="banner-title">
        <h1 class="first-title">Hangi</h1>
        <h1 class="second-title">
          Immergez vous dans la scène de crime avant d'y pénétrer
        </h1>
        <a class="btn btn-primary banner btn-xl" href="#nousSommes"
          >En savoir plus</a
        >
      </div>
    </div>
  </section>
</template>

<script></script>

<style scoped>
.hero-img-header.big-hero-header {
  padding-top: 52rem;
  padding-bottom: calc(10rem - 72px);
  background: linear-gradient(
      to bottom,
      rgba(68, 66, 92, 0.233) 0,
      rgba(103, 52, 56, 0.233) 100%
    ),
    url("@/assets/img/test.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
}
.hero-img-header {
  width: 100%;
  height: auto;
}
.banner-title {
  color: var(--banner-title-bg-color);
  display: block;
  left: 50%;
  position: absolute;
  text-align: center;
  top: 58%;
  transform: translate(-50%, -50%);
  min-width: 100%;
  z-index: 2;
  min-height: auto;
}

h1 {
  font-family: var(--police-title);
  font-size: 35px;
  font-weight: 700;
  letter-spacing: 4px;
  line-height: 1.3;
  text-transform: uppercase;
  margin-bottom: 0.5em;
  margin-top: 100px;
  text-shadow: 2px 2px 3px rgb(0, 0, 0);
  display: flex;
  visibility: inherit;
  justify-content: center;
  position: relative;
  z-index: 6;
}
h1.first-title {
  font-size: 100px;
  margin-top: 100px;
}
h1.second-title {
  padding-bottom: 1.5em;
}

/* bouton banner */
.btn.btn-primary.banner {
  white-space: nowrap;
  color: rgba(255, 255, 255, 0.776);
  outline: none;
  box-shadow: none;
  box-sizing: border-box;
  cursor: pointer;
  visibility: inherit;
  border-radius: 30px;
  font-style: normal;
  border-width: 2px;
  border-style: solid;
  border-color: rgba(255, 255, 255, 0.523);
  background-color: rgba(255, 255, 255, 0.109);
  line-height: 15px;
  margin: 0px;
  padding: 12px 35px;
  letter-spacing: 0px;
  font-weight: 600;
  font-size: 15px;
  min-height: 0px;
  min-width: 0px;
  opacity: 20;
  transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  transform-origin: 50% 50% 0px;
  margin-bottom: 40px;
}
.btn.btn-primary.banner:hover {
  border-color: #fff;
  background-color: var(--banner-button-after-bg-color);
  color: var(--banner-button-text-after-bg-color);

  transition: 0.25s ease-in-out;
}
@media screen and (max-width: 310px) {
  h1.first-title {
    font-size: 60px;
  }
}
</style>
