<template>
  <footer class="footer text-center pt-5">
    <div class="container">
      <div class="row">
        <div class="col-3">
          <a target="bank" href="https://www.gendarmerie.interieur.gouv.fr/">
            <img
              class="img-thumbnail"
              style="border: none"
              src="/partenaires/Gendarmerie-logo.webp"
          /></a>
        </div>
        <div class="col-6">
          <div class="container px-4 px-lg-5">
            <ul class="reseau list-inline mb-5">
              <li class="list-inline-item me-3">
                <a href="https://www.facebook.com/InanixSAS/" target="back">
                  <font-awesome-icon :icon="['fab', 'facebook']" size="4x"
                /></a>
              </li>
              <li class="list-inline-item me-3">
                <a href="https://twitter.com/dronotique" target="back">
                  <font-awesome-icon :icon="['fab', 'twitter']" size="4x"
                /></a>
              </li>
              <li class="list-inline-item me-3">
                <a
                  href="https://www.youtube.com/channel/UCxlV2gODveA-J5YaMB1FMOQ"
                  target="back"
                >
                  <font-awesome-icon :icon="['fab', 'youtube']" size="4x"
                /></a>
              </li>
              <li class="list-inline-item me-3">
                <a
                  href="https://www.instagram.com/dronotique/?hl=fr"
                  target="back"
                >
                  <font-awesome-icon :icon="['fab', 'instagram']" size="4x"
                /></a>
              </li>
              <li class="list-inline-item me-3">
                <a
                  href="https://www.linkedin.com/company/inanix/"
                  target="back"
                >
                  <font-awesome-icon :icon="['fab', 'linkedin']" size="4x"
                /></a>
              </li>
            </ul>
            <div class="contact">
              <ul class="list-inline mb-3">
                <li class="list-item me-3">
                  <router-link to="/">Inanix</router-link>
                </li>
                <li class="list-item me-3">
                  <a href="mailto:contact@inanix.fr">contact@inanix.fr</a>
                </li>
                <li class="list-item me-3">
                  <a href="tel:+33627586024">Tél. +33 (0)6.27.58.60.24</a>
                </li>
                <li class="list-item me-3">
                  <a
                    target="_blank"
                    href="https://goo.gl/maps/6fcJZBcJgmfufJFH7"
                    >48 rue d'Antrain 35700 RENNES</a
                  >
                </li>
              </ul>
            </div>
            <div class="loi">
              <ul class="list-inline mb-2">
                <li class="list-inline-item me-3">
                  <router-link to="/MentionLegal">Mentions Légales</router-link>
                </li>
                <li class="list-inline-item me-3">
                  <router-link to="/CGU">CGU</router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-3">
          <a href="https://inanix.com/">
            <img
              class="img-thumbnail"
              style="border: none"
              src="cropped-logo_dronotique-4.png "
              width="180"
              height="175"
          /></a>
        </div>
      </div>
    </div>
  </footer>
</template>
<script></script>

<style scoped>
a {
  color: var(--main-bg-color);
}

a:hover {
  color: var(--footer-after-bg-color);
  transition: 0.5s ease-in-out;
}
.contact a {
  font-size: 16px;
  text-decoration: none;
  color: var(--footer-contact-before-bg-color);
}
.contact a:hover {
  text-decoration: none;
  color: var(--footer-contact-after-bg-color);
}
.loi a {
  text-decoration: none;
  color: var(--main-bg-color);
}

.loi a:hover {
  color: var(--footer-after-bg-color);
  transition: 0.25s ease-in-out;
}
@media screen and (max-width: 450px) {
  .reseau.list-inline {
    padding-left: 0;
    list-style: none;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 46px;
  }
}
</style>
