import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import VueClick from "vue-click";
import "@/assets/css/color.css";
// fontawesome
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faFacebook,
  faTwitter,
  faInstagram,
  faLinkedin,
  faBuffer,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(
  faFacebook,
  faTwitter,
  faInstagram,
  faLinkedin,
  faBuffer,
  faYoutube
);

createApp(App)
  .use(router, VueClick)
  .component("font-awesome-icon", FontAwesomeIcon)
  .mount("#app");
