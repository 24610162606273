<template>
  <section class="" id="demonstration">
    <div class="container">
      <div class="text-head">
        <h2>Demonstration</h2>
      </div>
      <iframe
        width="1340"
        height="600"
        src="https://www.youtube.com/embed/vjHnY1RVWQs"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style scoped>
.container {
  display: contents;
}
iframe {
  margin-top: 40px;
  margin-bottom: 40px;
}

@media screen and (max-width: 450px) {
  iframe {
    width: 350px;
    height: 220px;
  }

  .text-head > h2 {
    font-size: 35px;
  }
}
</style>
