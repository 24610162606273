<template>
  <section class="section-padding" id="hangi">
    <div class="container">
      <div class="row">
        <div class="col-xl-6 mt-auto mb-auto ml-auto">
          <div class="text-head">
            <h2>Prix de l’audace 2020</h2>
          </div>
          <p class="desc-text">
            Le drone HANGI a été récompensé dans le cadre du
            <strong>Prix de l’Audace en 2020</strong>, décerné par la fondation
            <strong>Maréchal Leclerc de Hauteclocque</strong> et organisé
            par<strong> l’Agence de l’innovation de défense (AID)</strong>. Plus
            d’une trentaine d’innovateurs issus des trois armées, de
            l’état-major des armées, de la Direction générale de l’armement et
            de la gendarmerie nationale participaient à ce concours
            d’innovations.
          </p>
        </div>
        <div class="col-xl-6 mt-auto mb-auto mr-auto">
          <img
            class="image-fluid"
            src="@/assets/img/actu/prixdelaudace2020.webp"
            alt="QuiSommeNous"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script></script>

<style scoped>
img.image-fluid {
  max-width: 100%;
  height: auto;
  margin-top: 166px;
  margin-bottom: 50px;
  border-radius: 10px;
  padding-top: 120px;
}
.btn-primary.entreprise {
  background-color: var(--quisommenous-button-before-bg-color);
  border-color: var(--quisommenous-button-border-before-bg-color);
}
.btn-primary.entreprise:hover {
  background-color: var(--quisommenous-button-after-bg-color);
  border-color: var(--quisommenous-button-after-before-bg-color);
}
@media screen and (max-width: 450px) {
  .text-head > h2 {
    font-size: 35px;
  }
  .img.image-fluid {
    margin-top: auto;
    padding-top: auto;
  }
}
</style>
