<template>
  <section class="section-padding" id="nousSommes">
    <div class="container">
      <div class="row">
        <div class="col-xl-6 mt-auto mb-auto mr-auto">
          <img
            class="image-fluid"
            src="@/assets/img/accueil.webp"
            alt="QuiSommeNous"
          />
        </div>
        <div class="col-xl-6 mt-auto mb-auto ml-auto">
          <div class="text-head">
            <h2>HANGI</h2>
          </div>
          <p class="desc-text">
            HANGI est le premier drone développé en partenariat avec
            <strong
              >l'Institut de Recherche Criminelle de la Gendarmerie
              nationale</strong
            >. Sa caractéristique première est qu’il n’émet pas de
            <strong>turbulence</strong> ou de <strong>flux d’air</strong> vers
            le sol grâce à un ballon d'hélium qui stabilise le drone. Cette
            <strong>innovation</strong> permet de préserver la scène de crime et
            de ne pas fausser l'analyse.
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script></script>

<style scoped>
img.image-fluid {
  max-width: 100%;
  height: auto;
  margin-top: 166px;
  margin-bottom: 50px;
  padding: 2em;
  border-radius: 3em;
}
.btn-primary.entreprise {
  background-color: var(--quisommenous-button-before-bg-color);
  border-color: var(--quisommenous-button-border-before-bg-color);
}
.btn-primary.entreprise:hover {
  background-color: var(--quisommenous-button-after-bg-color);
  border-color: var(--quisommenous-button-after-before-bg-color);
}
.desc-text {
  line-height: 2.71;
}
.text-head > h2 {
  font-size: 5vw;
}
.desc-text {
  font-size: 1vw;
}
</style>
