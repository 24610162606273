<template>
  <section class="section-padding-bleu text-white text-center" id="savoirfaire">
    <div class="container px-4 px-lg-5">
      <div class="content-section-heading text-head light">
        <h2>informations</h2>
      </div>
      <div class="row gx-4 gx-lg-4 align-center">
        <div class="col-xl-6 col-md-6 mb-5 mt-xl-5 mb-lg-0">
          <span class="service-icon rounded-circle mx-auto mb-3"
            ><i class="fa fa-light fa-address-card"></i
          ></span>
          <h4><strong>Caractéristiques</strong></h4>
          <p class="text-faded mb-0">
            Le drone indoor dirigeable est équipé d’une caméra, d’une batterie,
            d’un ballon d’hélium et dispose d’une autonomie de
            <strong>30 minutes</strong>. La maniabilité d'Hangi lui permet de
            passer les portes et d'être contrôlable dans n’importe quelle
            situation. En effet, le drone peut monter au plafond en cas de
            problème technique, toujours
            <strong>sans interférer avec la scène de crime</strong>.
          </p>
        </div>
        <div class="col-xl-6 col-md-6 mb-5 mt-xl-5 mb-lg-0">
          <span class="service-icon rounded-circle mx-auto mb-3"
            ><i class="fa fa-light fa-video-camera"></i
          ></span>
          <h4><strong>Camera</strong></h4>
          <p class="text-faded mb-0">
            Le drone HANGI est équipé d’une caméra grand-angle de type fish-eye
            orientable de <strong>5MPx</strong> proposant une vision à
            <strong>360 degrés</strong> sur <strong>90</strong>. Le
            télé-pilotage s’effectue grâce à des lunettes d’immersions, une
            transmission vidéo <strong>HD faible latence</strong> et un
            affichage tête haute <strong>(HUD)</strong> présentant les
            principales informations de vol.
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script></script>

<style scoped>
section#savoirfaire {
  padding-bottom: -10px;
}
.text-faded {
  margin-left: 5em;
  margin-right: 5em;
  color: var(--white-text-bg-color);
}
.rounded-circle {
  border-radius: 50% !important;
}
.service-icon {
  background-color: var(--second-bg-color);
  color: var(--white-text-bg-color);
  height: 7rem;
  width: 7rem;
  display: block;
  line-height: 7rem;
  font-size: 2.25rem;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.57);
}

.container {
  padding-bottom: 5em;
  padding-top: 5em;
  height: 100%;
}
h4 {
  font-family: var(--police-title);
  text-transform: uppercase;
}
p {
  font-family: var(--police-desc);
  font-size: 18px;
  font-weight: 400;
  line-height: 1.71;
  padding-top: 0;
  margin-bottom: 0;
  text-align: center;
  position: relative;
  line-height: 1.3;
}
.text-head {
  margin-bottom: 4vw;
}
@media screen and (max-width: 450px) {
  .text-head > h2 {
    font-size: 35px;
  }
  .text-faded {
    width: 290px;
    text-align: center;
    margin: auto;
  }
}
</style>
